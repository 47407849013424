import { useState, useEffect } from 'react'
import useSWR from 'swr'
import useT from '@hooks/useTranslation'
import { apiFetcherPost as apiFetcher } from '@api/apiFetcher'

import styles from 'styles/Hint.module.css'

const HINTED_TYPES = ['text', 'range']
const MODEL_VISUAL_MAP = {
    'S-Cross': 'SX4 S-cross',
} // TODO: alias in db

const ALL_VISUAL_MAP = {
    // 'Ostrów Mazowiecki': 'Ostrów Mazowiecka',
}

const SEPARATOR = '--'

const withHint = ({ name, type = 'text', cache, hint, methods, selectlike, onImmediateChange, isSearchInput }) => { // lastHintClear
    if (notEligible(type, hint)) return null
    const { url, deps = [], exact, minLength, staticValues = [] } = hint

    return function Hint ({ children, currentValue, setCurrentValue = ()=>({}) }) {
        const [focus, setFocus] = useState(false)
        const [hints, setHints] = useState([])
        const [triggerFetch, setTriggerFetch] = useState(false)

        const hasDeps = deps?.length > 0
        const depValues = hasDeps
            ? methods.getValues(deps)
            : null
        const depsAvailable = depValues && depValues.every(dep => Boolean(dep))
        const depState = depsAvailable && JSON.stringify(depValues)
        const minLengthRequirement = minLength && currentValue?.length

        useEffect(() => {
            const blur = () => setFocus(false)
            document.addEventListener('click', blur)
            return () => document.removeEventListener('click', blur)
        }, [])

        useEffect(() => {
            if (!focus) return
            if (hasDeps ? depsAvailable : true) setTriggerFetch(true)
        }, [focus, depState, hasDeps, depsAvailable])

        useEffect(() => {
            if (minLengthRequirement) setTriggerFetch(minLengthRequirement >= currentValue?.length)
        }, [minLengthRequirement, currentValue?.length])

        useEffect(() => {
            if (hasDeps && !depsAvailable) {
                setHints([])
                if (setCurrentValue) setCurrentValue('')
            }
        }, [focus, hasDeps, depsAvailable, setCurrentValue])

        const select = (value) => {
            methods.setValue(name, value)
            methods.onChange && methods.onChange({ name, value })
            if (onImmediateChange) onImmediateChange(value)
            if (setCurrentValue) setCurrentValue(value)
            if (focus) setFocus(false)
        }

        const shouldFetch = triggerFetch && focus
        const { data, error } = useSWR(
            shouldFetch
                ? hintApiUrl(url, { deps, depValues, minLength, currentValue })
                : null, apiFetcher)

        if (shouldFetch && data) {
            const _data = staticValues?.length ? [...staticValues, SEPARATOR, ...data] : data
            if (cache) cache.put(_data)
            setHints(_data)
            setTriggerFetch(false)
            if (exact && _data.length === 1 && !isSearchInput) select(_data[0])
        }

        if (shouldFetch && error) {
            setTriggerFetch(false)
        }

        const onClick = (e) => {
            if (!focus) document.body.click()
            e.stopPropagation()
            setFocus(!focus)
        }

        const onSelect = (e) => {
            e.stopPropagation()
            select(e.target.getAttribute('value'))
            if (focus) e.preventDefault()
        }

        return (
            <div
                className={styles.wrapper}
                onClick={onClick}>

                {children}
                {focus && hints?.length > 0
                    && <Hints
                            currentValue={currentValue}
                            hints={hints}
                            map={name === 'model' ? MODEL_VISUAL_MAP : ALL_VISUAL_MAP}
                            onSelect={onSelect}
                            selectlike={selectlike} />}
            </div>
        )
    }
}

function Hints ({ currentValue = '', hints = [], map = {}, onSelect, selectlike }) {
    if (hints.length === 0) return null

    const filtered =
        (selectlike || !currentValue)
            ? hints
            : hints.filter(hint => String(hint).toLowerCase().startsWith(String(currentValue || '').toLowerCase()) && hint !== SEPARATOR)

    const items = filtered
        .map(hint => {
            if (hint === SEPARATOR) {
                return <li key={SEPARATOR} className={styles.hint} style={{opacity:0.25}}>{hint}</li>

            } else {
                return <li
                    key={hint}
                    value={hint}
                    onClick={onSelect}
                    className={styles.hint}>

                        {useT(map[hint] || hint)}
                </li>
            }
        })

    return items.length > 0
        ? <HintsBox>
            {items}
          </HintsBox>

        : null
}

function HintsBox ({ children }) {
    return (
        <ul className={styles.hints}>
            {children}
        </ul>
    )
}

function notEligible (type, hint) {
    return !hint || !HINTED_TYPES.includes(type)
}

function hintApiUrl (url, depState) {
    const { minLength, currentValue } = depState

    if (minLength) {
        if (currentValue?.length >= minLength) {
            return `/api/hints/${url}?q=${currentValue}`
        } else {
            return null
        }
    }

    const { query = '', isValid } = depsToQuery(depState)
    if (!isValid) return null
    return `/api/hints${url}${query}`
}

const NO_DEPS = { query: '', isValid: true }
const DEPS_NOT_FULFILLED = { query: '', isValid: false }
function depsToQuery ({ deps, depValues }) {
    if (deps?.length < 1) return NO_DEPS
    if (deps?.length !== depValues?.length) return DEPS_NOT_FULFILLED

    const query = []
    for (let i = 0; i < deps.length; i++) {
        const name = deps[i]
        const value = depValues[i]
        if (!value) {
            return DEPS_NOT_FULFILLED
        } else {
            query.push(`${name}=${value}`)
        }
    }

    return {
        query: '?' + query.join('&'),
        isValid: true,
    }
}

export default withHint
export {
    HintsBox,
    hintApiUrl,
}